<template>
	<div class="list">
		<CheckoutCartItem v-for="item in items" :key="item.id" :item="item" />
		<CheckoutCartItem v-if="gift" :item="gift" gift />
	</div>
</template>

<script>
import CheckoutCartItem from '@/views/checkout/components/CheckoutCartItem.vue';

export default {
	name: 'CheckoutCartItemList',
	components: {
		CheckoutCartItem,
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.width < 750;
		},
		items() {
			return this.$store.getters.cart;
		},
		gift() {
			const giftTo = this.$store.getters.giftTo;
			if (giftTo) {
				return {
					text: `Gift to ${giftTo.username}#${giftTo.discriminator}`,
					count: 1,
					price: 0,
					user: giftTo,
				};
			}
			return null;
		},
	},
	methods: {},
	mounted() {},
};
</script>

<style scoped>
.list {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
}
</style>
