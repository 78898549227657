<template>
	<div :class="isMobile ? 'confirmation-mobile' : 'confirmation'">
		<ConfirmationDisplay />
		<CheckoutCart />
		<ConfirmationHeader v-if="isMobile" />
	</div>
</template>

<script>
import ConfirmationHeader from '@/views/confirmation/components/ConfirmationHeader.vue';
import CheckoutCart from '@/views/checkout/components/CheckoutCart.vue';
import ConfirmationDisplay from '@/views/confirmation/components/ConfirmationDisplay.vue';

export default {
	name: 'ConfirmationPage',
	components: {
		ConfirmationHeader,
		CheckoutCart,
		ConfirmationDisplay,
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.width <= 1020;
		},
	},
	mounted() {
		if (!this.$store.getters.paymentNonce?.dataValue) {
			this.$router.back();
		}
	},
};
</script>

<style scoped>
.confirmation {
	display: flex;
	max-width: 100%;
	width: 100vw;
	overflow-x: hidden;
}

.confirmation-mobile {
	padding: 40px;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column-reverse;
}
</style>
