<template>
	<div
		:class="isMobile ? 'confirmation-display-mobile' : 'confirmation-display'"
	>
		<ConfirmationHeader v-if="!isMobile" />
		<ConfirmationBody />
		<ConfirmationButtons />
	</div>
</template>

<script>
import ConfirmationHeader from '@/views/confirmation/components/ConfirmationHeader.vue';
import ConfirmationBody from '@/views/confirmation/components/ConfirmationBody.vue';
import ConfirmationButtons from '@/views/confirmation/components/ConfirmationButtons.vue';

export default {
	name: 'ConfirmationDisplay',
	components: {
		ConfirmationHeader,
		ConfirmationBody,
		ConfirmationButtons,
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.width < 1020;
		},
	},
	mounted() {},
};
</script>

<style scoped>
.confirmation-display {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	width: 100%;
	flex-grow: 1;
	padding: 50px 100px;
}

.confirmation-display-mobile {
	display: flex;
	flex-direction: column;
	width: 100%;
	flex-grow: 1;
	padding-top: 40px;
}
</style>
