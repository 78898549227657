<template>
	<div class="cc-buttons">
		<v-btn class="cc-button" height="45" min-width="100" @click="goBack">
			Back
		</v-btn>
		<v-spacer />
		<v-btn
			class="cc-button"
			height="45"
			min-width="100"
			color="success"
			@click="confirm"
			:loading="disablePay"
		>
			Confirm
		</v-btn>
	</div>
</template>

<script>
export default {
	name: 'ConfirmationButtons',
	data: () => ({
		disablePay: false,
	}),
	methods: {
		goBack() {
			this.$router.push('/checkout');
		},
		async confirm() {
			this.disablePay = true;
			try {
				const res = await this.$store.dispatch('completePayment');
				let text;
				if (res.status === 200) {
					text =
						'Thank you for the payment! The items should already be in your Discord account. Enjoy!';
				} else if (res.status === 202) {
					text =
						'Thank you for the payment! The item failed to arrive in your inventory. Please give it a few days to arrive!';
				} else {
					text =
						'Thank you for the payment! The items should already be in your Discord account. Enjoy!';
				}

				const opt = {
					text,
					imgUrl: 'owo-peek.png',
					confetti: true,
				};
				await this.$modal(opt).showInfo();
				this.$store.dispatch('clearCart');
				this.$store.dispatch('clearGiftTo');
				this.$router.push('/store');
			} catch (err) {
				console.error(err);
				if (err.response.status === 429) {
					const opt = {
						text: 'Too many payments! Please purchase these items later.',
						imgUrl: 'owo-cry.png',
					};
					await this.$modal(opt).showWarn();
				} else {
					const opt = {
						text: 'Payment failed! Please try again later.',
						imgUrl: 'owo-cry.png',
					};
					await this.$modal(opt).showError();
				}
				this.$router.back();
			} finally {
				this.disablePay = false;
			}
		},
	},
};
</script>

<style scoped>
.cc-buttons {
	width: 100%;
	padding: 0px;
	display: flex;
	justify-content: space-between;
	padding-top: 70px;
}

.cc-button {
	width: 20%;
	max-width: 200px;
}
</style>
