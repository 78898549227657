<template>
	<div :class="isMobile ? 'cart-mobile' : 'cart'">
		<div>
			<div class="text-h6 pb-2">Order Summary</div>
			<v-divider class="divider"></v-divider>
		</div>
		<div class="pb-2">
			<CheckoutCartItemList />
			<v-divider class="divider"></v-divider>
		</div>
		<div class="cart-total pt-2">
			<div class="text-h6">Total:</div>
			<div class="cart-price">
				<div class="text-body-1 text--secondary cart-usd">USD</div>
				<div class="text-h6">{{ total }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import CheckoutCartItemList from '@/views/checkout/components/CheckoutCartItemList.vue';

export default {
	name: 'CheckoutCart',
	components: {
		CheckoutCartItemList,
	},
	data: () => ({}),
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.width < 1020;
		},
		total() {
			return `$${this.$store.getters.totalCost}.00`;
		},
	},
};
</script>

<style scoped>
.cart {
	width: min-content;
	display: flex;
	flex-direction: column;
	padding: 40px;
	background: var(--v-background_dark-base);
	min-height: 100vh;
}

.cart-mobile {
	width: calc(100% + 80px);
	display: flex;
	flex-direction: column;
	padding: 80px 40px;
	margin-top: 40px;
	background: var(--v-background_dark-base);
	height: min-content;
}

.divider {
	max-width: 100%;
}

.cart-total {
	display: flex;
	justify-content: space-between;
}

.cart-price {
	display: flex;
}

.cart-usd {
	padding-top: 5px;
	padding-right: 4px;
}

.cart-detail {
	display: flex;
}
</style>
