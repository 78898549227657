<template>
	<div class="confirmation-body">
		<div class="text-h5 confirmation-title">
			You're almost done, {{ username }}! Please confirm your order.
		</div>
		<table class="confirmation-table">
			<tr>
				<td class="confirmation-table-key">Order Purchaser</td>
				<td class="confirmation-table-value">{{ username }}</td>
			</tr>
			<tr>
				<td class="confirmation-table-key">Order receiver</td>
				<td class="confirmation-table-value">{{ receiver }}</td>
			</tr>
			<tr>
				<td class="confirmation-table-key">Payment Method</td>
				<td class="confirmation-table-value">{{ paymentMethod }}</td>
			</tr>
			<tr>
				<td class="confirmation-table-key">Credit Card</td>
				<td class="confirmation-table-value">{{ cardNumber }}</td>
			</tr>
			<tr>
				<td class="confirmation-table-key">Amount</td>
				<td class="confirmation-table-value">{{ amount }}</td>
			</tr>
		</table>
	</div>
</template>

<script>
//import ConfirmationHeader from '@/views/confirmation/components/ConfirmationHeader.vue'

export default {
	name: 'ConfirmationDisplay',
	components: {
		//ConfirmationHeader,
		//ConfirmationBody
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.width < 750;
		},
		user() {
			return this.$store.getters.user;
		},
		username() {
			return this.user.username + '#' + this.user.discriminator;
		},
		receiver() {
			const gift = this.$store.getters.giftTo;
			return gift ? `${gift.username}#${gift.discriminator}` : this.username;
		},
		amount() {
			return `$${this.$store.getters.totalCost}.00`;
		},
		paymentMethod() {
			return this.$store.getters.paymentType;
		},
		cardNumber() {
			return '*' + this.$store.getters.fourDigits;
		},
	},
	mounted() {},
};
</script>

<style scoped>
.confirmation-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.confirmation-table {
	min-width: 300px;
	max-width: 500px;
	width: 80%;
	border-collapse: separate;
	border-spacing: 0;
	border: 1px solid var(--v-secondary-base);
	border-radius: 5px;
	-moz-border-radius: 5px;
	padding: 15px;
}

.confirmation-table tr {
	line-height: 60px;
}

.confirmation-title {
	margin-bottom: 30px;
	margin-top: 40px;
}

.confirmation-table-key {
	font-weight: 600;
}

.confirmation-table-value {
	text-align: right;
}
</style>
