<template>
	<div class="checkout-header">
		<div class="text-h4 header-text">
			<v-img contain max-width="124" src="@/assets/owo-peek.png"></v-img>
			<div class="checkout-text">Order Confirmation</div>
		</div>
		<v-breadcrumbs :items="breadcrumbs" class="checkout-breadcrumbs">
			<template v-slot:divider>
				<v-icon>mdi-chevron-right</v-icon>
			</template>
		</v-breadcrumbs>
	</div>
</template>

<script>
export default {
	name: 'ConfirmationHeader',
	data: () => ({
		breadcrumbs: [
			{
				text: 'Cart',
				disabled: false,
				href: '/store',
			},
			{
				text: 'Information',
				disabled: false,
				href: '/checkout',
			},
			{
				text: 'Payment',
				disabled: false,
				href: '/confirmation',
			},
		],
	}),
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.width < 750;
		},
	},
	mounted() {},
};
</script>

<style scoped>
.checkout-header {
	display: flex;
	width: 100%;
	align-items: flex-start;
	flex-direction: column;
}

.header-text {
	display: flex;
	align-items: bottom;
}

.checkout-breadcrumbs {
	padding-left: 0px;
	padding-top: 10px;
}

.checkout-text {
	padding-top: 30px;
	margin-bottom: -7px;
	padding-left: 10px;
}
</style>
